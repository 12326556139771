import './App.css';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
// import {useEffect} from "react";
// import ReactGA from 'react-ga';
import Homepage from './components/Homepage';
import Contact from "./components/Contact";
import About from "./components/About";
import TermsConditions from "./components/TermsConditions";
import PrivacyPolicy from "./components/PrivacyPolicy";
import QueryResult from "./components/QueryResult";
import Article from "./components/Article";
import CategoryPage from "./components/CategoryPage";
import NoMatch from "./components/NoMatch";
import CouponsPage from "./components/CouponsPage";
import Modal from "./components/Modal";


function App() {
    // const setGA = () => {
    //     ReactGA.initialize('G-74NSEQLTML');
    //     ReactGA.pageview('Init page view');
    // };
    // useEffect(()=>{
    //     setGA();
    // },[]);
  return (
      <div>
        <BrowserRouter>
          <Routes>
              <Route path='/' element={<Homepage />} />
              <Route path={'/privacy-policy'} element={<PrivacyPolicy />} />
              <Route path={'/terms-conditions'} element={<TermsConditions />} />
              <Route path={'/about'} element={<About />} />
              <Route path={'/contact'} element={<Contact />} />
              <Route path={'/queryresult'} element={<QueryResult />} />
              <Route path={'/coupons'} element={<CouponsPage />} />
              <Route path={'/article:id'} element={<Article/>} />
              <Route path={'/categories/:category'} element={<CategoryPage/>} />
              <Route path={'*'} element={<NoMatch/>}/>
          </Routes>
        </BrowserRouter>
      </div>
  );
}

export default App;
