import '../styles/ArticleCategoryPage.css';
import {useNavigate} from "react-router-dom";


const ArticleCategoryPage = (props) => {

    const DEBUG = false;
    let path = "https://contextualpalace.com";
    if (DEBUG) {
        path = "http://localhost:3000";
    }
    const navigate = useNavigate();

    const goTo = function () {
        const path = "/article" + props.id;
        navigate(path);
    }

    return (
        <div className='article-category-container'>
            <div className='photo-category-container'> <img className='article-photo' src={`${path}/posts-images/${props.image}`} alt='null' onClick={goTo} fetchpriority="high"/> </div>
            <div className='article-category-title'onClick={goTo}> {props.title} </div>
            <div className='article-category-content'>
                <p>
                    {props.content}
                </p>
            </div>
        </div>
    );
}

export default ArticleCategoryPage